import { Button, Row, Table, InputGroup, FormControl, Col } from "react-bootstrap";
import { GoSearch } from "react-icons/go"
import { BsPlusLg } from "react-icons/bs"
import {  useState } from "react";
import AzioniPerVerificati from "./AzioniPerVerificati";
import AggiungiAdminModal from "./AggiungiAdminModal";

export default function GestioneAdmin({
    partecipanti,
     setShowToast, 
     setMessaggioToast, 
     setTitoloToast, 
     setRicaricaPartecipanti, 
     comId}) {
    const [showAggiungiAdmin, setShowAggiungiAdmin] = useState(false)
    const [ricercaAdmin, setRicercaAdmin] = useState("")

    return (
        <>
            <div className="container-sm p-0 p-md-4">
                <Row className="d-flex justify-content-between mb-3">
                    <Col md={4} xs={12}>
                        <h4 className="w-auto"><strong>Admin registrati</strong></h4>
                    </Col>
                    <Col md={5} xs={12} className="text-md-end mb-2 mb-md-0">
                        <Button className="w-auto"
                            onClick={() => { setShowAggiungiAdmin(true) }}
                        >Aggiungi un admin <BsPlusLg className="m-auto" /></Button>
                    </Col>
                    <Col md={3} xs={12}>
                        <InputGroup>
                            <InputGroup.Text id="basic-addon1">
                                <GoSearch />
                            </InputGroup.Text>
                            <FormControl placeholder="Cerca nome..."
                                onChange={(ev) => { setRicercaAdmin(ev.target.value) }}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Cognome</th>
                            <th>Nome</th>
                            <th>Codice Fiscale</th>
                            <th >Azioni</th>
                        </tr>
                    </thead>
                    <tbody>
                        {partecipanti.length > 0 &&
                            partecipanti
                                .filter(el => {
                                    if (ricercaAdmin) {
                                        let nome_cognome = el.datiPartecipante.dparNome + " " + el.datiPartecipante.dparCognome
                                        return nome_cognome.toLowerCase().includes(ricercaAdmin)
                                    }
                                    else return true
                                })
                                .sort((a, b) => {
                                    // Se lo stato è lo stesso, ordina per cognome e poi per nome
                                    const cognomeA = a.datiPartecipante.dparCognome.toLowerCase();
                                    const cognomeB = b.datiPartecipante.dparCognome.toLowerCase();
                                    const nomeA = a.datiPartecipante.dparNome.toLowerCase();
                                    const nomeB = b.datiPartecipante.dparNome.toLowerCase();
                            
                                    if (cognomeA < cognomeB) return -1;
                                    if (cognomeA > cognomeB) return 1;
                                    if (nomeA < nomeB) return -1;
                                    if (nomeA > nomeB) return 1;
                                    return 0;
                                    
                                    // I partecipanti "attivi" prima di quelli "bloccati"
                                    if (a.parStato === 'attivo') return -1;
                                    if (b.parStato === 'attivo') return 1;
                                    if (a.parStato === 'bloccato') return 1;
                                    if (b.parStato === 'bloccato') return -1;
                                
                                    return 0;
                                })
                                .map((el, index) => (
                                    <tr key={index} className={el.parStato === 'bloccato' ? "row-blocked":''}>
                                        <td>{el.datiPartecipante.dparCognome}</td>
                                        <td>{el.datiPartecipante.dparNome}</td>
                                        <td>{el.datiPartecipante.dparCf}</td>
                                        <td>
                                            <AzioniPerVerificati partecipante={el} 
                                            setRicaricaPartecipanti={setRicaricaPartecipanti} />
                                        </td>
                                    </tr>
                                ))}
                    </tbody>
                </Table>
            </div>

            <AggiungiAdminModal
                show={showAggiungiAdmin}
                setShow={setShowAggiungiAdmin}
                comId={comId}
                setShowToast={setShowToast}
                setMessaggioToast={setMessaggioToast}
                setTitoloToast={setTitoloToast}
                setRicaricaPartecipanti={setRicaricaPartecipanti} />
        </>
    )
}