import React from "react";
import { useState } from "react";
import { Row, Col, Container, Badge, Button } from "react-bootstrap";

export default function AreeUrbane(props) {

    // Valore temporaneo nell'input
    let [value, setValue] = useState("")

    // Controlla se l'area è già presente
    let isInList = area => {
        return props.aree.includes(area);
    }

    // Si occupa della gestione della pressione del tasto invio, virgola o tab
    let handleKeyDown = evt => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            var val = value.trim();
            if (val && !isInList(val)) {
                props.setAree(old => [...old, val])
                setValue("")
            }
        }
    }

    // Gestione della scrittura di una nuova area
    let handleChange = evt => {
        setValue(evt.target.value)
    }

    // Eliminazione della area
    let handleDelete = item => {
        props.setAree(old => old.filter(el => el !== item))
    }

    return (
        <>
            <input className="w-100"
                value={value}
                placeholder="Scrivi l'area urbana che desideri inserire e premi `Invio`"
                onKeyDown={handleKeyDown}
                onChange={handleChange}
            />

            <Row className="p-0 mt-2">
                {props.aree && props.aree.map((item, index) => (
                    <Col className="d-flex justify-content-center mb-1 " key={index} md={12} lg={4}>
                        <Badge key={index} pill bg="light" className="w-100 d-flex justify-content-between badge-categorie">
                            <Container fluid="xs" className="my-auto">
                                {item}
                            </Container>
                            <Button variant="light"
                                onClick={() => handleDelete(item)}>
                                &times;
                            </Button>
                        </Badge>
                    </Col>
                ))}
            </Row>
        </>
    )
}

