//import "bootstrap/dist/css/bootstrap.css";
import "./custom.scss"
import './App.css';
import { Col, Row } from "react-bootstrap";
import { useEffect, useState, React } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

//componenti
import Cruscotto from "./componenti/Admin/Cruscotto/Cruscotto";
import LogoComune from "./componenti/Admin/LogoComune";
import Sidebar from "./componenti/Admin/Sidebar"
import Petizioni from "./componenti/Admin/Petizioni/Petizioni";
import Referendum from "./componenti/Admin/Referendum/Referendum";
import BilanciPartecipativi from "./componenti/Admin/BilanciPartecipativi/BilanciPartecipativi";
import Segnalazioni from "./componenti/Admin/Segnalazioni/Segnalazioni";
import ImpostazioniGenerali from "./componenti/Admin/ImpostazioniGenerali/ImpostazioniGenerali"

import { AxiosInterceptor } from "./componenti/Utilities/AuthTokenUtils";

import Homepage from "./componenti/Utente/Homepage/Homepage";
import NavbarUtente from "./componenti/Utente/Homepage/NavbarUtente";
import EsploraPetizioni from "./componenti/Utente/Petizioni/EsploraPetizioni";
import CreaPetizione from "./componenti/Utente/Petizioni/CreaPetizione";
import PaginaPetizione from "./componenti/Utente/Petizioni/PaginaPetizione";
import CreaReferendum from "./componenti/Utente/Referendum/CreaReferendum";
import EsploraReferendum from "./componenti/Utente/Referendum/EsploraReferendum";
import PaginaReferendum from "./componenti/Utente/Referendum/PaginaReferendum";
import EsploraBilanci from "./componenti/Utente/Bilancio/EsploraBilanci";
import PaginaBilancio from "./componenti/Utente/Bilancio/PaginaBilancio";
import PaginaProposta from "./componenti/Utente/Bilancio/PaginaProposta";
import CreaProposta from "./componenti/Utente/Bilancio/CreaProposta";
import Footer from "./componenti/Utente/Homepage/Footer";
import Login from "./componenti/Utente/Login/Login";

//servizi
import ComuneService from "./services/ComuneService";
import PartecipanteService from "./services/PartecipanteService";
import EsploraSegnalazioni from "./componenti/Utente/Segnalazioni/EsploraSegnalazioni";
import CreaSegnalazione from "./componenti/Utente/Segnalazioni/CreaSegnalazione";
import PaginaSegnalazione from "./componenti/Utente/Segnalazioni/PaginaSegnalazione";
import SPIDcard from "./componenti/Utente/Login/SPIDcard";
import Scrolltotop from "./componenti/Utilities/ScrollToTop";
import PaginaNonTrovata from "./componenti/Utente/Error/PaginaNonTrovata"
import Partecipanti from "./componenti/Admin/Partecipanti/Partecipanti";

import VerificaToken from "./componenti/Utente/VerificaToken";
import LoginOk from "./componenti/Utente/Login/LoginOk";
import PaginaErroreGenerico from "./componenti/Utente/Error/PaginaErroreGenerico";

export default function App() {
  const comId = 1

  let [comune, setComune] = useState({})
  let [ricaricaComune, setRicaricaComune] = useState(true)
  let [partecipante, setPartecipante] = useState({})

  //FIXME: prendila da db
  const centro_mappa = [45.063074259101356, 7.681102829088041]

  //stato che governa quale tab è attiva nella pagina del bilancio
  const [activeTab, setActiveTab] = useState("informazioni")

  // per permettere il ricaricamento del referendum dopo la fase di voto, è necessario mantenere uno stato
  // che ha il compito di forzare l'esecuzione della use effect nel componente PaginaReferendum
  const [ricaricaReferendum, setRicaricaReferendum] = useState(true)

  //STATI 
  //tabAttivaPetizioni, tabAttivaReferendum, tabAttivaSegnalazioni, tabAttivaBilanci, tabAttivaImpostazioni
  // necessari alla sidebar e ai tab widget per cambiare la tab attiva
  const [tabAttivaPetizioni, setTabAttivaPetizioni] = useState("petizioni")
  const [tabAttivaReferendum, setTabAttivaReferendum] = useState("referenda")
  const [tabAttivaSegnalazioni, setTabAttivaSegnalazioni] = useState("segnalazioni")
  const [tabAttivaBilanci, setTabAttivaBilanci] = useState("bilanciPartecipativi")
  const [tabAttivaImpostazioni, setTabAttivaImpostazioni] = useState("impostazioni-comune")

  //STATI 
  // mostraTabellaPetizioni, mostraTabellaReferendum, mostraTabellaSegnalazioni, mostraTabellaBilanci
  // mostrano la tabella con tutti gli elementi (parte admin)
  let [mostraTabellaPetizioni, setMostraTabellaPetizioni] = useState(true)
  let [mostraTabellaReferendum, setMostraTabellaReferendum] = useState(true)
  let [mostraTabellaSegnalazioni, setMostraTabellaSegnalazioni] = useState(true)
  let [mostraTabellaBilanci, setMostraTabellaBilanci] = useState(true)

  const [caricaPartecipante, setCaricaPartecipante] = useState(true)

  AxiosInterceptor()

  function aggiornaRicaricaPartecipante() {
    setCaricaPartecipante(true)
  }

  useEffect(() => {
    if (caricaPartecipante) {
      const partecipante = sessionStorage.getItem("partecipante");
  
      if (partecipante) {
        let cfChiaro = "";
  
        try {
          const base64Str = partecipante.replace(/[^A-Za-z0-9+/=]/g, '');
          cfChiaro = atob(base64Str);
        } catch (e) {
          console.error('Errore durante la decodifica Base64:', e);
          return; // Interrompe l'esecuzione se c'è un errore nella decodifica
        }
  
        // Chiamata al servizio per ottenere i dati del partecipante tramite il codice fiscale
        PartecipanteService.findByCf(cfChiaro)
          .then(res => {
            setPartecipante(res.data);
          })
          .catch(err => {
            console.log("Errore al caricamento del partecipante", err);
          })
          .finally(() => {
            setCaricaPartecipante(false); 
          });
      } else {
        setCaricaPartecipante(false);
      }
    }
  }, [caricaPartecipante]); // Dipendenza su `caricaPartecipante`

  useEffect(() => {
    if (ricaricaComune) {
      ComuneService.getComune(comId)
        .then(res => {
          setComune(res.data)
        })
        .catch(err => {
          setComune({})
          //TODO: GESTIONE DELL'ERRORE
        })
        .finally(() => setRicaricaComune(false))
    }
  }, [ricaricaComune, comId])


  return (
      <Router>
        <Scrolltotop />
        <Routes>
          <Route exact path="/login" element={<Login comune={comune} aggiornaRicaricaPartecipante={aggiornaRicaricaPartecipante }  />} />
          {/* PARTE UTENTE */}
          <Route path="/*" element={
            <>
              <NavbarUtente comune={comune} partecipante={partecipante} setPartecipante={setPartecipante} />
              <Routes>
                <Route path="/loginok" element={<LoginOk />} />
                <Route path="/pagina-di-errore" element={<PaginaErroreGenerico />} />

                <Route path="/spid/*" element={<SPIDcard setPartecipante={setPartecipante} />} />

                <Route exact path="/" element={<Homepage comune={comune} partecipante={partecipante} setCaricaPartecipante={setCaricaPartecipante} />} />
                <Route exact path="/esplora-petizioni" element={<EsploraPetizioni comune={comune} partecipante={partecipante} />} />
                <Route exact path="/crea-petizione" element={<CreaPetizione comune={comune} partecipante={partecipante} />} />
                <Route exact path="/petizione" element={
                  <PaginaPetizione comune={comune} partecipante={partecipante} />
                } />

                <Route exact path="/esplora-referendum" element={<EsploraReferendum comune={comune} partecipante={partecipante} />} />
                <Route exact path="/crea-referendum" element={<CreaReferendum comune={comune} partecipante={partecipante} />} />
                <Route exact path="/referendum" element={
                  <PaginaReferendum comune={comune} partecipante={partecipante} ricaricaReferendum={ricaricaReferendum} setRicaricaReferendum={setRicaricaReferendum} />
                } />
                <Route exact path="/esplora-bilanci-partecipativi" element={
                  <EsploraBilanci comune={comune} setActiveTab={setActiveTab} partecipante={partecipante} />
                } />

                <Route exact path="/bilancio" element={
                  <PaginaBilancio comune={comune} activeTab={activeTab} setActiveTab={setActiveTab} partecipante={partecipante} />} />
                <Route exact path="/proposta" element={<PaginaProposta comune={comune} partecipante={partecipante} />} />
                <Route exact path="/crea-proposta" element={<CreaProposta comune={comune} partecipante={partecipante} />} />

                <Route exact path="/esplora-segnalazioni" element={<EsploraSegnalazioni comune={comune} partecipante={partecipante} centro_mappa={centro_mappa} />} />
                <Route exact path="/crea-segnalazione" element={<CreaSegnalazione comune={comune} partecipante={partecipante} centro_mappa={centro_mappa} />} />
                <Route exact path="/segnalazione" element={
                  <PaginaSegnalazione comune={comune} partecipante={partecipante} centro_mappa={centro_mappa} />
                } />
                <Route exact path="/verifica-token" element={<VerificaToken/>} />

                <Route exact path="/pagina-non-trovata" element={<PaginaNonTrovata />} />
              </Routes>
              <Footer />
            </>
          } />

          {/* PARTE ADMIN */}
          <Route path="/admin/*" element={
            <>
              <LogoComune comune={comune} />
              <Row>
                <Sidebar comune={comune} ricaricaComune={ricaricaComune} partecipante={partecipante}

                  setTabAttivaPetizioni={setTabAttivaPetizioni}
                  setMostraTabellaPetizioni={setMostraTabellaPetizioni}

                  setTabAttivaReferendum={setTabAttivaReferendum}
                  setMostraTabellaReferendum={setMostraTabellaReferendum}

                  setTabAttivaSegnalazioni={setTabAttivaSegnalazioni}
                  setMostraTabellaSegnalazioni={setMostraTabellaSegnalazioni}

                  setTabAttivaBilanci={setTabAttivaBilanci}
                  setMostraTabellaBilanci={setMostraTabellaBilanci}

                  setTabAttivaImpostazioni={setTabAttivaImpostazioni} />
                <Col className="mt-5 pt-3 m-0" style={{ minHeight: "100vh", minWidth: "80vw" }}>
                  <Routes>
                    <Route path="" element={<Navigate to="/admin/cruscotto"/>}/>
                    <Route path="/cruscotto" element={<Cruscotto comune={comune} partecipante={partecipante}/>} />
                    <Route path="/petizioni" element={<Petizioni comune={comune} setRicaricaComune={setRicaricaComune} tabAttivaPetizioni={tabAttivaPetizioni} setTabAttivaPetizioni={setTabAttivaPetizioni} mostraTabella={mostraTabellaPetizioni} setMostraTabella={setMostraTabellaPetizioni} partecipante={partecipante}/>} />
                    <Route exact path="/referendum" element={<Referendum comune={comune} setRicaricaComune={setRicaricaComune} tabAttivaReferendum={tabAttivaReferendum} setTabAttivaReferendum={setTabAttivaReferendum} mostraTabella={mostraTabellaReferendum} setMostraTabella={setMostraTabellaReferendum} partecipante={partecipante}/>} />
                    <Route exact path="/segnalazioni" element={<Segnalazioni comune={comune} setRicaricaComune={setRicaricaComune} tabAttivaSegnalazioni={tabAttivaSegnalazioni} setTabAttivaSegnalazioni={setTabAttivaSegnalazioni} mostraTabella={mostraTabellaSegnalazioni} setMostraTabella={setMostraTabellaSegnalazioni} partecipante={partecipante}/>} />
                    <Route exact path="/bilanci-partecipativi" element={<BilanciPartecipativi comune={comune} setRicaricaComune={setRicaricaComune} tabAttivaBilanci={tabAttivaBilanci} setTabAttivaBilanci={setTabAttivaBilanci} mostraTabella={mostraTabellaBilanci} setMostraTabella={setMostraTabellaBilanci} partecipante={partecipante}/>} />
                    <Route exact path="/impostazioni-generali" element={<ImpostazioniGenerali comune={comune} setRicaricaComune={setRicaricaComune} tabAttivaImpostazioni={tabAttivaImpostazioni} setTabAttivaImpostazioni={setTabAttivaImpostazioni} partecipante={partecipante} />} />
                    <Route exact path="/partecipanti" element={<Partecipanti comune={comune} setRicaricaComune={setRicaricaComune} partecipante={partecipante} />} />
                  </Routes>
                </Col>
              </Row>
            </>
          } />
        </Routes>
      </Router>

  );
}

