import axios from 'axios'

const COMUNE_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'comune'

const headers = { 'Content-Type': 'application/json' }

class ComuneService {
    getComune(id) {
        return axios.get(COMUNE_REST_API_URL + `/${id}`)
    }

    aggiornaComune(comune){
        return axios.post(COMUNE_REST_API_URL + "/save", comune, headers)
    }
}

const comuneServiceInstance = new ComuneService()
export default comuneServiceInstance;