//TODO fetch from DB

import logoComuneDefault from '../../immagini/stemma_comune_prova/stemma_comune_di_prova.png'
import { Navbar } from 'react-bootstrap';

export default function LogoComune(props) {
    
    const getLogoUrl = () => {
        if (props.comune.comStemmaFile)
            return "data:image/png;base64," + props.comune.comStemmaFile
        else
            return logoComuneDefault
    } 

    return (
        <div className='position-absolute top-0 end-0 mt-2'>
            <Navbar.Brand href="/" style={{ marginLeft: "1em" }}>
                <img alt="Logo comune" src={getLogoUrl()} width="50" height="45" />
                <span>{props.comune.comNome}</span>
            </Navbar.Brand>
        </div>
    )
}