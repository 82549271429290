import React from "react"
import { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Card, Figure } from "react-bootstrap"
import { FiUpload } from 'react-icons/fi'
import { BsFileEarmarkRichtext } from "react-icons/bs"
import { GoTrashcan } from "react-icons/go"
import { IoMdAdd } from "react-icons/io"
import Fase from "./BilancioFase";
import AzioniBilancio from "./AzioniBilancio";
import BilancioPartecipativoService from "../../../services/BilancioPartecipativoService"
import FasiService from "../../../services/BilancioPartecipativoFasiService" 
import ModalConferma from "../../Utilities/ModalConferma";
import { statiBilancio } from "../../Utilities/statiElementEnum";

const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export default function GestisciBilancio(props) {

    // Estrazione del bilancio da visualizzare nella pagina dettaglio
    let bilancio = props.bilanci.filter(el => el.bilId === props.bilId)[0]

    // Valore che permette l'inizializzazione una volta sola al caricamento della pagina
    let [init, setInit] = useState(true)

    // Stati per gestire i campi modificabili del bilancio
    let [bilId, setBilId] = useState("")
    let [bilStato, setBilStato] = useState("")
    let [bilEdizione, setBilEdizione] = useState("")

    // EDIZIONE
    const getBilEditionForm = () => {
        let years = bilancio.bilEdizione.replace("Edizione ", "").split("/")

        if (years.length === 2)
            return years.join(" - ")
        else
            return years[0]
    }
    const getNewBiledizione = () => {
        if (
            form.bilDataInizio &&
            form.bilDataChiusura
        ) {
            if (
                dayjs(form.bilDataInizio, 'YYYY-MM-DD', true).isValid() === true &&
                dayjs(form.bilDataChiusura, 'YYYY-MM-DD', true).isValid() === true
            ) {
                if (dayjs(form.bilDataChiusura).year() > dayjs(form.bilDataInizio).year())
                    return [dayjs(form.bilDataInizio).year(), dayjs(form.bilDataChiusura).year()].join(" - ")
                else if (dayjs(form.bilDataInizio).year() === dayjs(form.bilDataChiusura).year())
                    return dayjs(form.bilDataInizio).year()
            }
        } else if (form.bilDataInizio) {
            if (dayjs(form.bilDataInizio, 'YYYY-MM-DD', true).isValid() === true)
                return dayjs(form.bilDataInizio).year()
        } else if (form.bilDataChiusura) {
            if (dayjs(form.bilDataChiusura, 'YYYY-MM-DD', true).isValid() === true)
                return dayjs(form.bilDataChiusura).year()
        }

        return ""
    }
    const getBilEditionString = () => {
        if (bilEdizione.toString().includes(" - "))
            return "Edizione " + bilEdizione.split(" - ").join("/")
        else return "Edizione " + bilEdizione
    }

    // DATE
    /*
     * La data inizio viene duplicata nella variabile dataInizioCurrent per evitare che in fase
     * di modifica di un bilancio esistente si possa inserire una data di inizio
     * inferiore a quella memorizzata in db
    */
    let dataInizioCurrent = (props.gestisci !== "addNew") ? bilancio.bilDataInizio : dayjs()
    /*
     * La data inizio di ogni fase viene duplicata nell'array dateInizioFasiCurrent per evitare che in fase
     * di modifica di un bilancio esistente (e di una fase esistente) si possa inserire una data di inizio di una fase 
     * inferiore a quella memorizzata in db
    */
    let dateInizioFasiCurrent = props.gestisci !== "addNew" ? bilancio.fasi.map(fase => {
        return fase.fasDataInizio
    }) : []
    let permettiSovrapposizioneFasi = true
    let permettiDateInizioFasiPrecedentiDateInizioDb = true
    let permettiDataInizioBilancioPrecedenteDataInizioDb = true
    let permettiDataInizioPrimaDiOggi = false

    // BANNER
    let inputFile = useRef(null)
    const [file, setFile] = useState();
    const [uploadedFilesMetadata, setUploadedFilesMetadata] = useState([])
    const acceptedExtensions = ["image/*", "image/png", "image/jpg", "image/jpeg"]
    const [erroreAllegati, setErroreAllegati] = useState("")
    const handleDisplayFileDetails = async () => {
        if (inputFile?.files && inputFile.files[0]
            && inputFile.files[0].size <= 30000 * 1024 //controlla grandezza file < 3000kB 
            && acceptedExtensions.includes(inputFile.files[0].type) //controlla estensione
        ) {
            setUploadedFilesMetadata(inputFile.files[0])
            setFile(URL.createObjectURL(inputFile.files[0]));
            setErroreAllegati("")
        } else {
            if (uploadedFilesMetadata.filter(file => file.name === inputFile.files[0].name).length > 1) setErroreAllegati("Non puoi caricare file con lo stesso nome.")
            else if (!acceptedExtensions.includes(inputFile.files[0].type)) setErroreAllegati("Il formato del file scelto non è accettabile.")
            else if (!inputFile.files[0].size <= 102400) setErroreAllegati("Il file scelto è troppo grande. Riprova.")
        }
    }
    const handleUpload = (event) => {
        event.preventDefault()
        inputFile?.click();
    };
    const deleteFile = () => {
        setUploadedFilesMetadata([])
        setFile()
    }

    // FASI
    let [fasiToDelete, setFasiToDelete] = useState([])
    const aggiungiFase = () => {
        let dataInizio
        let dataFine

        if (form.bilFasi.length === 0) {
            dataInizio = dayjs().format('YYYY-MM-DD')
            dataFine = dayjs().add(2, 'M').format('YYYY-MM-DD')
        } else {
            dataInizio = dayjs(form.bilFasi[(form.bilFasi.length - 1)].fasDataFine).add(1, 'd').format('YYYY-MM-DD')
            dataFine = dayjs(dataInizio).add(2, 'M').format('YYYY-MM-DD')
        }
        setForm({
            ...form,
            bilFasi: [...form.bilFasi, {
                fasTitolo: "",
                fasDescrizione: "",
                fasDataInizio: dataInizio,
                fasDataFine: dataFine,
                fasFlInvioProposte: false,
                fasFlVotazioni: false,
                fasOrdine: (form.bilFasi.length + 1)
            }]
        })
    }
    const handleDeleteFase = (fase_d) => {
        setMostraModaleDeleteFase(true)
        setFaseToDelete(fase_d)
    }
    const deleteFase = (fase_d) => {
        setMostraModaleDeleteFase(false)

        if (props.gestisci === 'controlla')
            setFasiToDelete([...fasiToDelete, fase_d])
    
        setForm({
            ...form,
            bilFasi: form.bilFasi
                .filter(fase => fase.fasOrdine !== fase_d.fasOrdine)
                .map(fase => {
                    if (fase.fasOrdine > fase_d.fasOrdine)
                        return {...fase, fasOrdine: (fase.fasOrdine - 1)}
                    return fase
                })
        })
    }

    // VALIDAZIONE FORM
    const [form, setForm] = useState({
        bilTitolo: "",
        bilDataInizio: dayjs().format('YYYY-MM-DD'),
        bilDataChiusura: dayjs().add(2, 'M').format('YYYY-MM-DD'),
        bilDescrizione: "",
        bilFasi: []
    })
    const [validato, setValidato] = useState(false)
    const [errors, setErrors] = useState({})
    const mandatoryField = () => {
        if (["addNew", "controlla"].includes(props.gestisci))
            return <span>*</span>

        return ""
    }
    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })

        if (["bilDataInizio", "bilDataChiusura"].includes(field)) {
            let newBiledizione = getNewBiledizione()
            if (newBiledizione !== "")
                setBilEdizione(newBiledizione)
        }

        // Controllo se ci sono errori, e li rimuovo dall'oggetto Errors
        if (!!errors[field])
            setErrors({
                ...errors,
                [field]: null
            })
    }
    const setFaseField = (field, faseUpdated, faseIndex) => {
        let newBilFasi = form.bilFasi.map((fase, index) => {
            if (index === faseIndex)
                return faseUpdated
            return fase
        })

        if (
            errors.errorsFasi !== undefined &&
            errors.errorsFasi[faseIndex][field] !== undefined
        )
            delete errors.errorsFasi[faseIndex][field]

        setForm({
            ...form,
            bilFasi: newBilFasi
        })
    }
    const getValidationStyle = (field) => {
        return (validato ? (errors[field] ? "is-invalid" : "is-valid") : "")
    }
    const isErrors = (newErrors) => {
        let ret = false

        if (Object.keys(newErrors).length > 1)
            ret = true
        else {
            newErrors.errorsFasi.forEach(fase => {
                if (Object.keys(fase).length > 0)
                    ret = true
            })
        }

        return ret
    }

    // FINESTRE MODALI
    const [mostraModaleOperazione, setMostraModaleOperazione] = useState(false)
    const [mostraModaleSuccesso, setMostraModaleSuccesso] = useState(false)
    const [mostraModaleErrore, setMostraModaleErrore] = useState(false)
    const [parolaChiave, setParolaChiave] = useState("")
    const [titolo, setTitolo] = useState("")
    const [messaggio, setMessaggio] = useState("")
    const [mostraModaleDeleteFase, setMostraModaleDeleteFase] = useState(false)
    const [faseToDelete, setFaseToDelete] = useState(null)

    // AZIONI
    const handleSubmit = (saveType, e) => {
        e.preventDefault()

        setValidato(true);

        // Ottieni i nuovi errori
        let newErrors = checkBilancio()

        if (isErrors(newErrors)) {
            // Presenza di errori: inserimento nell'oggetto Errors e submit bloccato
            setErrors(newErrors)
        } else {
            // Assenza di errori! Submit del form abilitato
            if (saveType === "salva-bozza")
                salvaBilancio(saveType)
            else {
                setMostraModaleOperazione(true)
                if (saveType === "pubblica") {
                    setParolaChiave("Pubblica")
                    setTitolo("Pubblicazione bilancio")
                    setMessaggio("Sei sicuro di voler pubblicare il bilancio? L'operazione è irreversibile!")
                }
                if (saveType === "salva") {
                    setParolaChiave("Salva")
                    setTitolo("Salva il bilancio")
                    setMessaggio("Sei sicuro di voler modificare il bilancio? L'operazione è irreversibile!")
                }
            }
        }
    }

    const salvaBilancio = (saveType) => {
        let bilancio = {
            bilEdizione: getBilEditionString(),
            bilTitolo: form.bilTitolo,
            bilDataInizio: form.bilDataInizio,
            bilDataChiusura: form.bilDataChiusura,
            bilDescrizione: form.bilDescrizione,
            comune: props.comune,
            fasi: form.bilFasi
        }

        saveType = saveType.toLowerCase()

        if (saveType === "salva-bozza")
            bilancio.bilStato = statiBilancio.NonPubblicato
        else if (["pubblica", "salva"].includes(saveType))
            bilancio.bilStato = statiBilancio.InCorso

        if (props.gestisci === "controlla")
            bilancio.bilId = bilId

        var formData = new FormData()
        formData.append("bilancio", JSON.stringify(bilancio))
        formData.append("file", uploadedFilesMetadata)

        BilancioPartecipativoService.saveBilancio(formData)
            .then(() => {
                if (fasiToDelete.length > 0)
                    FasiService.eliminaFasi(fasiToDelete)
            })
            .then(() => {
                props.setDirty(true)
                if (saveType === "salva-bozza") {
                    setTitolo("Salva bozza bilancio")
                    setMessaggio("Bozza salvata con successo")
                } else if (saveType === "pubblica") {
                    setTitolo("Pubblica bilancio")
                    setMessaggio("Bilancio pubblicato con successo")
                } else if (saveType === "salva") {
                    setTitolo("Salva bilancio")
                    setMessaggio("Bilancio salvato con successo")
                }

                setMostraModaleOperazione(false)
                setMostraModaleSuccesso(true)
            })
            .catch(() => {
                setMostraModaleOperazione(false)
                setMostraModaleErrore(true)
                if (saveType === "salva-bozza") {
                    setTitolo("Errore nel salvataggio della bozza")
                    setMessaggio("Si è verificato un errore durante il salvataggio della bozza del bilancio.")
                }
                if (saveType === "pubblica") {
                    setTitolo("Errore nella pubblicazione del bilancio")
                    setMessaggio("Si è verificato un errore durante la pubblicazione del bilancio.")
                }
                if (saveType === "salva") {
                    setTitolo("Errore nel salvataggio del bilancio")
                    setMessaggio("Si è verificato un errore durante il salvataggio del bilancio.")
                }
            })
    }

    // CHECK
    const checkBilancio = () => {
        const { bilTitolo, bilDataInizio, bilDataChiusura, bilDescrizione, bilFasi } = form
        let newErrors = {}

        newErrors = checkTitolo(bilTitolo, newErrors)
        newErrors = checkDate(bilDataInizio, bilDataChiusura, newErrors)
        newErrors = checkDescrizione(bilDescrizione, newErrors)
        newErrors = checkFasi(bilFasi, newErrors)

        return newErrors
    }

    const checkTitolo = (bilTitolo, newErrors) => {
        if (!bilTitolo || bilTitolo === '')
            newErrors.bilTitolo = "Inserisci il titolo"

        return newErrors
    }

    const checkDate = (bilDataInizio, bilDataChiusura, newErrors) => {
        let error = false
        const dataInizio = dayjs(bilDataInizio)
        const dataChiusura = dayjs(bilDataChiusura)
        const msgs = {
            dataInizioRequired: "Inserisci la data di inizio",
            dataFineRequired: "Inserisci la data di chiusura",
            invalidDate: "Controllare i valori delle date inserite",
            dataInizioPrimaDiOggi: "La data di inizio non può essere inferiore alla data di oggi",
            dataInizioPrecedenteDataDb: "La data di inizio non può essere inferiore a quella precedentemente salvata",
            dataFinePrimaDataInizio: "La data di chiusura non può essere antecedente alla data di inizio"
        }

        // La data di inizio non può essere vuota
        if (!bilDataInizio || bilDataInizio === '') {
            newErrors.bilDataInizio = msgs.dataInizioRequired
            error = true
        }

        // La data di chiusura non può essere vuota
        if (!bilDataChiusura || bilDataChiusura === '') {
            newErrors.bilDataChiusura = msgs.dataFineRequired
            error = true
        }

        if (!error) {
            // La data di inizio deve essere valida
            if (dayjs(bilDataInizio, 'YYYY-MM-DD', true).isValid() === false) {
                newErrors.bilDataInizio = msgs.invalidDate
                error = true
            }
            // La data di chiusura deve essere valida
            if (dayjs(bilDataChiusura, 'YYYY-MM-DD', true).isValid() === false) {
                newErrors.bilDataChiusura = msgs.invalidDate
                error = true
            }
        }

        // In inserimento del bilancio la data di inizio non può essere inferiore a oggi
        if (!error) {
            if (props.gestisci === "addNew" && !permettiDataInizioPrimaDiOggi) {
                let today = dayjs(new Date(dayjs().year(), dayjs().month(), dayjs().date()))
                if (dayjs(dataInizio).isBefore(today, 'd')) {
                    newErrors.bilDataInizio = msgs.dataInizioPrimaDiOggi
                    error = true
                }
            }
        }

        // In modifica del bilancio la data di inizio non può essere inferiore a quella salvata in db
        if (!error) {
            if (props.gestisci !== "addNew" && !permettiDataInizioBilancioPrecedenteDataInizioDb) {
                if (dayjs(dataInizio).isBefore(dayjs(dataInizioCurrent), 'd')) {
                    newErrors.bilDataInizio = msgs.dataInizioPrecedenteDataDb
                    error = true
                }
            }
        }

        // La data di chiusura non può essere antecedente alla data di inizio
        if (!error) {
            if (dayjs(dataChiusura).isBefore(dayjs(dataInizio), 'd')) {
                newErrors.bilDataChiusura = msgs.dataFinePrimaDataInizio
                error = true
            }
        }

        return newErrors
    }

    const checkDescrizione = (bilDescrizione, newErrors) => {
        if (!bilDescrizione || bilDescrizione === '')
            newErrors.bilDescrizione = "Inserisci la descrizione"

        return newErrors
    }

    const checkFasi = (bilFasi, newErrors) => {
        let fasePrec
        let errorsFasi = []
        bilFasi.forEach(fase => {
            let errors = {}
            errors = checkFasiTitolo(fase, errors)
            errors = checkFasiDescrizione(fase, errors)
            errors = checkFasiDate(fase, fasePrec, errors)
            errorsFasi.push(errors)
            fasePrec = fase
        })
        newErrors.errorsFasi = errorsFasi

        return newErrors
    }

    const checkFasiTitolo = (fase, newErrors) => {
        if (!fase.fasTitolo || fase.fasTitolo === '')
            newErrors.fasTitolo = "Inserisci il titolo"

        return newErrors
    }

    const checkFasiDescrizione = (fase, newErrors) => {
        if (!fase.fasDescrizione || fase.fasDescrizione === '')
            newErrors.fasDescrizione = "Inserisci la descrizione"

        return newErrors
    }

    const checkFasiDate = (fase, fasePrec, newErrors) => {
        const msgs = {
            dataInizioRequired: "Inserisci la data di inizio",
            dataFineRequired: "Inserisci la data di fine",
            invalidDate: "Controllare i valori delle date inserite",
            dateFaseInPeriodoBilancio: "Le date di inizio e fine del bilancio devono essere comprese tra le date di inizio e chiusura del bilancio",
            dataFinePrimaDataInizio: "La data di fine non può essere antecedente alla data di inizio",
            sovrapposizioneFasiNonPermessa: "La data di inizio deve essere successiva alla data di inizio della fase precedente",
            dataInizioPrecedenteDataDb: "La data di inizio non può essere inferiore a quella precedentemente salvata",
        }
        let error = false

        // Le date di inizio e fine non possono essere vuota
        if (!fase.fasDataInizio || fase.fasDataInizio === '') {
            newErrors.fasDataInizio = msgs.dataInizioRequired
            error = true
        }
        if (!fase.fasDataFine || fase.fasDataFine === '') {
            newErrors.fasDataFine = msgs.dataFineRequired
            error = true
        }

        // Le date di inizio e fine devono essere valide
        if (!error) {
            if (dayjs(fase.fasDataInizio, 'YYYY-MM-DD', true).isValid() === false) {
                newErrors.fasDataInizio = msgs.invalidDate
                error = true
            }
            if (dayjs(fase.fasDataFine, 'YYYY-MM-DD', true).isValid() === false) {
                newErrors.fasDataFine = msgs.invalidDate
                error = true
            }
        }

        // Il periodo della fase deve essere compreso all'interno del periodo dell'intero bilancio
        if (!error) {
            if (
                dayjs(fase.fasDataInizio).isBefore(dayjs(form.bilDataInizio), 'd') ||
                dayjs(fase.fasDataInizio).isAfter(dayjs(form.bilDataChiusura), 'd')
            ) {
                newErrors.fasDataInizio = msgs.dateFaseInPeriodoBilancio
                error = true
            }
            if (
                dayjs(fase.fasDataFine).isAfter(dayjs(form.bilDataChiusura), 'd') ||
                dayjs(fase.fasDataFine).isBefore(dayjs(form.bilDataInizio), 'd')
            ) {
                newErrors.fasDataFine = msgs.dateFaseInPeriodoBilancio
                error = true
            }
        }

        // La data di fine non può essere antecedente alla data di inizio
        if (!error) {
            if (dayjs(fase.fasDataFine).isBefore(dayjs(fase.fasDataInizio), 'd')) {
                newErrors.fasDataFine = msgs.dataFinePrimaDataInizio
                error = true
            }
        }

        /*
         * Se il flag permettiSovrapposizioneFasi è false le fasi non possono intersecarsi tra di loro: 
         * la data di inizio di una fase deve essere successiva alla data di fine della fase precedente
        */
        if (!error) {
            if (!permettiSovrapposizioneFasi) {
                if (fasePrec) {
                    if (!(dayjs(fase.fasDataInizio).isAfter(dayjs(fasePrec.fasDataFine), 'd'))) {
                        newErrors.fasDataInizio = msgs.sovrapposizioneFasiNonPermessa
                        error = true
                    }
                }
            }
        }

        // In modifica del bilancio la data di inizio non può essere inferiore a quella salvata in db
        if (!error) {
            if (
                props.gestisci !== "addNew" &&
                !permettiDateInizioFasiPrecedentiDateInizioDb
            ) {
                if (dateInizioFasiCurrent[fase.fasOrdine - 1] !== undefined) {
                    let dataInizioFaseCurrent = dateInizioFasiCurrent[fase.fasOrdine - 1]
                    if (dayjs(fase.fasDataInizio).isBefore(dayjs(dataInizioFaseCurrent), 'd')) {
                        newErrors.fasDataInizio = msgs.dataInizioPrecedenteDataDb
                        error = true
                    }
                }
            }
        }

        return newErrors
    }

    useEffect(() => {
        if (init) {
            if (props.gestisci === "addNew") {
                setBilStato(statiBilancio.NonPubblicato)
                setBilEdizione(dayjs().year())
            }

            if (props.gestisci === "controlla") {
                setBilId(bilancio.bilId)
                setBilStato(bilancio.bilStato)
                setBilEdizione(getBilEditionForm())

                setFile(`data:image/png;base64,${bilancio.bilBanner}`)
                setUploadedFilesMetadata({ name: bilancio.bilBannerNome })

                setForm({
                    bilTitolo: bilancio.bilTitolo,
                    bilDataInizio: (bilancio.bilDataInizio ? bilancio.bilDataInizio : dayjs().format('YYYY-MM-DD')),
                    bilDataChiusura: (bilancio.bilDataChiusura ? bilancio.bilDataChiusura : dayjs().add(2, 'M').format('YYYY-MM-DD')),
                    bilDescrizione: bilancio.bilDescrizione,
                    bilFasi: bilancio.fasi
                })
            }
            setInit(false)
        }
        // eslint-disable-next-line
    }, [init, props.gestisci, bilancio])

    return (
        <>
            <Container>
                {props.gestisci === "addNew" && <h2 className="title2">Nuovo bilancio partecipativo</h2>}
                {props.gestisci === "controlla" && <h2 className="title2">Controlla il bilancio partecipativo</h2>}

                {["addNew", "controlla"].includes(props.gestisci) &&
                    <Row>
                        <Col className="mb-4 italic required-alert">I campi contrassegnati da un asterisco (*) sono obbligatori</Col>
                    </Row>
                }

                <Form className={validato === true ? "validato" : "non-validato"}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <strong>Titolo: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    value={form.bilTitolo}
                                    onChange={e => setField('bilTitolo', e.target.value)}
                                    className={getValidationStyle("bilTitolo")}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    <strong>{errors.bilTitolo}</strong>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Group className="mb-3" >
                                <Form.Label>
                                    <strong>Data inizio: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    value={form.bilDataInizio}
                                    onChange={e => setField('bilDataInizio', e.target.value)}
                                    className={getValidationStyle("bilDataInizio")}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    <strong>{errors.bilDataInizio}</strong>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3" >
                                <Form.Label>
                                    <strong>Data chiusura: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    value={form.bilDataChiusura}
                                    onChange={e => setField('bilDataChiusura', e.target.value)}
                                    className={getValidationStyle("bilDataChiusura")}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    <strong>{errors.bilDataChiusura}</strong>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3 datepicker-cm-validation">
                                <Form.Label>
                                    <strong>Edizione: </strong>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={bilEdizione}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <strong>Descrizione: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    value={form.bilDescrizione}
                                    onChange={e => setField('bilDescrizione', e.target.value)}
                                    className={"no-resize " + getValidationStyle("bilDescrizione")}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    <strong>{errors.bilDescrizione}</strong>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mt-4 mb-3">
                        <Col md={6}>
                            <strong>Carica banner</strong>
                        </Col>
                        <Col md={6} className="text-end">
                            <div>
                                <input
                                    className="d-none"
                                    type="file"
                                    multiple={true}
                                    ref={el => inputFile = el}
                                    onChange={handleDisplayFileDetails}
                                />
                                <Button
                                    onClick={handleUpload}
                                    disabled={uploadedFilesMetadata.name}>
                                    <FiUpload className="me-2" size="1em" /> Carica
                                </Button>
                            </div>
                        </Col>
                        {erroreAllegati &&
                            <Form.Label className="mt-1 text-danger d-block"><strong>
                                {erroreAllegati}
                            </strong>
                            </Form.Label>}
                    </Row>
                    {uploadedFilesMetadata.name && file &&
                        <Card className="text-center mb-4">
                            <Card.Header className="d-flex justify-content-between">
                                <strong className="my-auto">
                                    <BsFileEarmarkRichtext size="1.5em" />
                                    {uploadedFilesMetadata.name}
                                </strong>
                                <Button variant="outline-danger" onClick={() => { deleteFile(uploadedFilesMetadata.name) }}>
                                    <GoTrashcan size="1.5em" />
                                </Button>
                            </Card.Header>
                            <Figure className="p-1 allegatoSegnalazione">
                                <Figure.Image
                                    width={800}
                                    height={500}
                                    src={file}
                                />
                            </Figure>
                        </Card>
                    }
                    <Row>
                        <Col>
                            <h4 className="title3">Fasi</h4>
                        </Col>
                    </Row>
                    {form.bilFasi
                        .sort((a, b) => { return a.fasOrdine - b.fasOrdine })
                        .map((fase, index) => (
                            <Fase
                                key={index}
                                index={index}
                                fase={fase}
                                setFaseField={setFaseField}
                                mandatoryField={mandatoryField}
                                errors={errors}
                                validato={validato}
                                handleDeleteFase={handleDeleteFase}
                            />
                        ))
                    }
                    <Row>
                        <Col>
                            <Button
                                size="sm"
                                onClick={aggiungiFase}
                            >
                                Aggiungi fase  <IoMdAdd style={{ fontSize: "1.3em" }} />
                            </Button>
                        </Col>
                    </Row>

                    <AzioniBilancio
                        setMostraTabella={props.setMostraTabella}
                        bilStato={bilStato}
                        handleSubmit={handleSubmit}
                    />
                </Form>
            </Container>

            {/* modale di conferma operazione */}
            <ModalConferma
                mostra={mostraModaleOperazione}
                setMostra={setMostraModaleOperazione}
                mode={"continua"}
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma={`Si, ${parolaChiave.toLowerCase()}`}
                btnAnnulla="No, annulla"
                azioneAnnulla={() => { setMostraModaleOperazione(false) }}
                azioneConferma={() => { salvaBilancio(parolaChiave) }}
            />

            <ModalConferma
                mostra={mostraModaleDeleteFase}
                setMostra={setMostraModaleDeleteFase}
                mode={"continua"}
                titolo="Eliminazione fase"
                messaggio="Confermi l'eliminazione della fase?"
                bodyAlign="text-center"
                btnConferma={`Si, Elimina`}
                btnAnnulla="No, annulla"
                azioneAnnulla={() => { setMostraModaleDeleteFase(false) }}
                azioneConferma={() => { deleteFase(faseToDelete) }}
            />

            {/* modali di esito operazione */}
            <ModalConferma
                mostra={mostraModaleSuccesso}
                setMostra={setMostraModaleSuccesso}
                mode="success"
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma="Torna a tutti i bilanci"
                azioneConferma={() => { props.setMostraTabella(true) }}
            />

            {/* modali di errore */}
            <ModalConferma
                mostra={mostraModaleErrore}
                setMostra={setMostraModaleErrore}
                mode="error"
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={() => {
                    setMostraModaleErrore(false)
                }}
            />
        </>
    )
}