import dayjs from "dayjs"
import { statiBilancio, statiPetizione, statiReferendum } from "./statiElementEnum"

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)


// funzione per cambiare stato SE è una petizione
function getStatusPetizione(petizione, comune) {
    let stato = petizione.petStato
    if (comune) {
        if ( petizione.petStato === statiPetizione.InCorso && petizione.firmePetizione &&
            (petizione.firmePetizione.length + petizione.petFirmeOffline) >= comune.impostazioniComune.imcSogliaPetizioni
        )
            stato = statiPetizione.InAttesa

        if (petizione.petStato === statiPetizione.InCorso && dayjs().isAfter(dayjs(petizione.petFineRaccoltaFirme))
        ) {
            if (petizione.firmePetizione &&
                (petizione.firmePetizione.length + petizione.petFirmeOffline) >= comune.impostazioniComune.imcSogliaPetizioni
            )
                stato = statiPetizione.InAttesa
            else
                stato = statiPetizione.FirmeNonRaggiunte
        }
    }
    return stato
}

// funzione per cambiare stato  SE è un referendum
function getStatusReferendum(referendum, comune) {
    let stato = referendum.refStato

    if (comune && referendum.refStato === statiReferendum.InCorso) {
        let soglie = comune.impostazioniComune.soglie
        let firmeAttuali = referendum.firmeReferendum && (referendum.firmeReferendum.length + parseInt(referendum.refFirmeOffline))
        let lastSoglia
        let firmeRichieste

        if (referendum.soglieReferendum && referendum.soglieReferendum.length > 0) {
            let lastSogliaReferendum = referendum.soglieReferendum[referendum.soglieReferendum.length - 1]
            lastSoglia = soglie.filter(soglia => (soglia.sogOrdine === (lastSogliaReferendum.soglia.sogOrdine + 1) && soglia.sogActive === true))[0]
        } else if (soglie && soglie.length > 0)
            lastSoglia = soglie[0]

        firmeRichieste = lastSoglia && lastSoglia.sogValore

        if (firmeAttuali > (firmeRichieste - 1))
            stato = statiReferendum.InAttesa
    }

    if (dayjs().isAfter(dayjs(referendum.refFineRaccoltaFirme)) && referendum.refStato === statiReferendum.InCorso)
        stato = statiReferendum.FirmeNonRaggiunte

    return stato
}

// funzione per cambiare stato SE è un bilancio
function getStatusBilancio(bilancio) {
    let stato = bilancio.bilStato

    if (dayjs().isAfter(dayjs(bilancio.bilDataChiusura)) && bilancio.bilStato === statiBilancio.InCorso)
        stato = statiBilancio.Concluso

    return stato
}


export {
    getStatusPetizione,
    getStatusReferendum,
    getStatusBilancio,
    
}