import { statiBilancio, statiPetizione, statiProposta, statiReferendum, statiSegnalazione } from "./statiElementEnum"

function getPetizioneColor(petizione) {
    switch (petizione.petStato) {
        case statiPetizione.InValidazione:
            return "warning"
        case statiPetizione.InCorso:
            return "specialBlue"
        case statiPetizione.InAttesa:
            return "warning"
        case statiPetizione.Conclusa:
            return "success"
        case statiPetizione.FirmeNonRaggiunte:
            return "danger"
        case statiPetizione.Rifiutata:
            return "danger"
        default:
            return ""
    }
}

function getReferendumColor(referendum) {
    switch (referendum.refStato) {
        case statiReferendum.InValidazione:
            return "warning"
        case statiReferendum.InCorso:
            return "specialBlue"
        case statiReferendum.Concluso:
            return "success"
        case statiReferendum.FirmeRaggiunte:
            return "success"
        case statiReferendum.FirmeNonRaggiunte:
            return "danger"
        case statiReferendum.InAttesa:
            return "warning"
        case statiReferendum.Rifiutato:
            return "danger"
        default:
            return ""
    }
}

function getSegnalazioneColor(segnalazione) {
    switch (segnalazione.segStato) {
        case statiSegnalazione.Chiusa:
            return "success"
        case statiSegnalazione.InAttesa:
            return "warning"
        case statiSegnalazione.Rifiutata:
            return "danger"
        default:
            return ""
    }
}

function getBilancioColor(bilancio) {
    switch (bilancio.bilStato) {
        case statiBilancio.NonPubblicato:
            return "warning"
        case  statiBilancio.Concluso:
            return "success"
        case  statiBilancio.InCorso:
            return "specialBlue"
        default:
            return ""
    }
}

function getPropostaColor(proposta) {
    switch (proposta.proStato) {
        case statiProposta.InValidazione:
            return "warning"
        case statiProposta.Pubblicata:
            return "specialBlue"
        case statiProposta.Vincitrice:
            return "success"
        case statiProposta.Rifiutata:
            return "danger"
        default:
            return ""
    }
}


export {
    getBilancioColor,
    getPropostaColor,
    getPetizioneColor,
    getReferendumColor,
    getSegnalazioneColor
}