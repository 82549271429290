import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { versione } from '../../Utilities/variables'
import logoPrologic from '../../../immagini/logo-prologic.png'

export default function Footer(props) {

    return (
        <>

            <Container fluid="true"  >
                <Card border="muted" style={{ background: "#f2f2f2" }}>
                    <Card.Body className="p-3">
                        <Row className='smallText text-center w-100'>
                            <Col xs={{span:12}} md={{span:4, offset:2}}>
                                <img alt="Logo Pro-logic Informatica" src={logoPrologic} width={"150px"} className='pb-2'/>
                                <div><a href="https://www.pro-logic.it"><strong>Prologic Informatica s.r.l.</strong></a></div>
                                <div className="pb-2" style={{ textDecorationLine: "none", color: "grey" }} > © Eleweb Partecipa, 2024, {versione}</div>
                            </Col>
                            <Col  xs={{span:12}} md={{span:4}}>
                                <div className='pt-4'>Via Arnaldo da Brescia, 9 10134 TORINO</div>
                                <a href="tel:+390113043223">Tel. +39 011 30 43223</a>
                                <div>Mail  &nbsp; <a href="mailto:info@pro-logic.it">info@pro-logic.it</a></div>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}