import React from "react"
import { useState, useEffect } from "react"
import { Container, Tabs, Tab } from "react-bootstrap"
import TabellaBilanci from "./TabellaBilanci"
import GestisciBilancio from "./GestisciBilancio"
import ProposteElenco from "./Proposte/ProposteElenco"
import ProposteGestisci from "./Proposte/ProposteGestisci"
import ImpostazioniBilanci from "./ImpostazioniBilanci"
import BilancioPartecipativoService from "../../../services/BilancioPartecipativoService"
import ModuloNonAttivo from "../ModuloNonAttivo";
import { useNavigate, useLocation } from "react-router-dom";
import PaginaNonTrovata from "../../Utente/Error/PaginaNonTrovata"

export default function BilanciPartecipativi(props) {
    const navigate = useNavigate();
    const location = useLocation();

    // Dirty bit
    let [dirty, setDirty] = useState(true)

    // Stoppa il ricaricamento dei dati quando vengono caricati la prima volta. Viene riattivato quando c'è bisogno di caricare nuovamente i dati del db
    // (dopo) un inserimento di righe
    let [stopLoading, setStopLoading] = useState(false)

    // Fetch bilanci
    let [bilanci, setBilanci] = useState([])

    // Stato che decide come deve essere gestito il bilancio
    let [gestisci, setGestisci] = useState("")

    // Stato che decide come deve essere gestita la proposta
    let [gestisciProposta, setGestisciProposta] = useState("")

    // Stato per l'applicazione del filtro stato sui bilanci
    let [statoFilter, setStatoFilter] = useState("")

    // Stato per l'applicazione del filtro stato sulle proposte
    let [statoPropostaFilter, setStatoPropostaFilter] = useState("")

    // Stato per l'applicazione del filtro stato sulle proposte
    let [areaFilter, setAreaFilter] = useState("")

    // Stato che passa l'id del bilancio
    let [bilId, setBilId] = useState("")

    let [proposta, setProposta] = useState()

    useEffect(() => {
        if (dirty && props.comune) {
            BilancioPartecipativoService.getAllByComune(props.comune.comId)
                .then(res => {
                    setBilanci(res.data)
                })
                .then(() => {
                    setStopLoading(false)
                })
                .catch((err) => {
                    setBilanci([])
                })
                .finally(() => {
                    if (props.comune.comId)
                        setDirty(false)
                })
        }
    }, [props.comune, dirty]);

    const toggleTab = (ev) => {
        if (ev.target.dataset.rrUiEventKey === "impostazioni") {
            props.setTabAttivaBilanci("impostazioni")
            navigate("/admin/bilanci-partecipativi#impostazioni")
        }
        if (ev.target.dataset.rrUiEventKey === "bilanciPartecipativi") {
            props.setTabAttivaBilanci("bilanciPartecipativi")
            navigate("/admin/bilanci-partecipativi#tutti-i-bilanci")
        }
    }
    useEffect(() => {
        if (location.hash === "#tutti-i-bilanci") props.setTabAttivaBilanci("bilanciPartecipativi")
        if (location.hash === "#impostazioni") props.setTabAttivaBilanci("impostazioni")
    }, [props, location])


    if (props.partecipante.parId && props.partecipante.ruolo.ruoFlAmministratore) {

    return (
        <Container fluid="md">
            <div className="d-flex justify-content-between">
                <h1 className="title">Bilancio Partecipativo</h1>
            </div>
            {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloBilanciAttivo ? (
                <Tabs activeKey={props.tabAttivaBilanci} id="uncontrolled-tab-example" className="mb-3"
                    onClick={toggleTab}>
                    <Tab eventKey="bilanciPartecipativi" title="Tutti i bilanci">
                        {
                            props.mostraTabella ? (
                                <TabellaBilanci
                                    bilanci={bilanci}
                                    setBilId={setBilId}
                                    setMostraTabella={props.setMostraTabella}
                                    setGestisci={setGestisci}
                                    statoFilter={statoFilter}
                                    setStatoFilter={setStatoFilter}
                                />
                            ) : (
                                gestisci !== "proposte-elenco" &&
                                    gestisci !== "proposta-gestisci" ? (
                                    <GestisciBilancio
                                        bilanci={bilanci}
                                        comune={props.comune}
                                        bilId={bilId}
                                        setMostraTabella={props.setMostraTabella}
                                        gestisci={gestisci}
                                        setGestisci={setGestisci}
                                        setDirty={setDirty}
                                    />
                                ) : (
                                    gestisci === "proposte-elenco" ? (
                                        <ProposteElenco
                                            comune={props.comune}
                                            bilanci={bilanci}
                                            bilId={bilId}
                                            setMostraTabella={props.setMostraTabella}
                                            setGestisci={setGestisci}
                                            setGestisciProposta={setGestisciProposta}
                                            statoPropostaFilter={statoPropostaFilter}
                                            setStatoPropostaFilter={setStatoPropostaFilter}
                                            areaFilter={areaFilter}
                                            setAreaFilter={setAreaFilter}
                                            setProposta={setProposta}
                                        />
                                    ) : (
                                        gestisci === "proposta-gestisci" &&
                                        <ProposteGestisci
                                            comune={props.comune}
                                            bilanci={bilanci}
                                            bilId={bilId}
                                            setGestisci={setGestisci}
                                            gestisciProposta={gestisciProposta}
                                            proposta={proposta}
                                            setProposta={setProposta}
                                            setDirty={setDirty}
                                        />
                                    )
                                )
                            )
                        }
                    </Tab>
                    <Tab eventKey="impostazioni" title="Impostazioni">
                        <ImpostazioniBilanci
                            bilanci={bilanci}
                            stopLoading={stopLoading}
                            setStopLoading={setStopLoading}
                            setDirty={setDirty}
                            comune={props.comune}
                            setRicaricaComune={props.setRicaricaComune}
                        />
                    </Tab>
                </Tabs>
            ) : (
                <ModuloNonAttivo moduleName="Bilanci Partecipativi" />
            )}
        </Container>
    )

}else{
    return(<PaginaNonTrovata/>)
}
}