import React, { useEffect,useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PartecipanteService from '../../services/PartecipanteService';
export default function VerificaToken() {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    
    const query = useQuery();
    const navigate=useNavigate();
    const token = query.get("token");
    const [adminConfermato,setAdminConfermato]=useState(false);

    useEffect(() => {
        PartecipanteService.confermaToken(token).then((response) => {
            if(response.status === 200 && response.data===true) {
                setAdminConfermato(true);
            }
            else
            {
                navigate("/pagina-non-trovata");
            }
        })
        .catch((error) => {
            console.log(error);
        });
        // eslint-disable-next-line
    }, []);

    
    
    return (
        adminConfermato && <>
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h1>Admin confermato</h1>
          <p>Clicca sul bottone sottostanze per accedere alla piattaforma:</p>
          <a 
            href="http://localhost:3000/" 
            style={{ 
              display: 'inline-block', 
              padding: '10px 20px', 
              color: 'white', 
              backgroundColor: '#007bff', 
              borderRadius: '5px', 
              textDecoration: 'none' 
            }}
            target="_blank" 
            rel="noopener noreferrer"
          >
            Accedi alla Piattaforma
          </a>
        </div>
        </>
      );
}