import React from "react";
import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";

const dayjs = require('dayjs')

export default function ImpostazioniSoglie(props) {
  let stepSoglie = props.stepSoglie

  let orderSoglieByOrdine = () => {
    props.soglie && props.soglie.sort(function (a, b) { return a.sogOrdine - b.sogOrdine });
    return props.soglie || [];
  }

  let editSoglia = (value, sogOrdine) => {
    if (!value) value = 0
    props.setSoglie(old => old.map(soglia => {
      //Esistono 2 casi
      //la soglia è da modificare
      if (
        soglia.sogOrdine === sogOrdine &&
        soglia.sogActive === true
      ) {
        if (soglia.sogId) {
          //1. la soglia ha un sogId
          return {
            'sogId': soglia.sogId,
            'sogOrdine': soglia.sogOrdine,
            'sogValore': value,
            'sogActive': soglia.sogActive
          }
        }
        else {
          //2. non esiste il sogId
          return {
            'sogOrdine': soglia.sogOrdine,
            'sogValore': value,
            'sogActive': soglia.sogActive
          }
        }
      }
      //la soglia non si deve modificare
      return soglia
    }))
  }

  let decreaseSoglie = () => {
    //elimina sempre l'ultima soglia, esistono due casi:
    // 1. l'ultima soglia ha già un sogId
    if (props.soglie.length > 0 && props.soglie[props.soglie.length - 1].sogId) {
      props.setSoglie(old => old.map(soglia => {
        if (
          soglia.sogOrdine === calcolaTotaleSoglieAttive() &&
          soglia.sogActive === true
        )
          return { ...soglia, sogActive: false, sogDataDisattivazione: dayjs().format('YYYY-MM-DD') }
        else
          return { ...soglia }
      }))
    }
    //2. l'ultima soglia non ha un sogId, quindi va semplicemente eliminata
    else {
      props.setSoglie(old => old.filter((el, index) => {
        if (index === old.length - 1) return false
        return true
      }))
    }
  }

  let increaseSoglie = () => {
    let sogOrdine, sogValore

    if (props.soglie.length > 0) {
      sogOrdine = calcolaTotaleSoglieAttive() + 1
      sogValore = props.soglie && parseInt(props.soglie.filter((value) => value.sogOrdine === (sogOrdine - 1) && value.sogActive === true)[0].sogValore) + stepSoglie
    } else {
      //non è stata mai aggiunta una soglia
      sogOrdine = 1
      sogValore = stepSoglie
    }

    props.setSoglie(old => [...old, {
      sogOrdine: sogOrdine,
      sogValore: sogValore,
      sogActive: true
    }])
  }

  let calcolaTotaleSoglieAttive = () => {
    return props.soglie && props.soglie.filter((value) => value.sogActive === true).length
  }

  return (
    <>
      <Col>
        <Form.Group as={Row} className="mb-4">
          <Form.Label column>
            <strong>Approvazioni</strong>
          </Form.Label>
        </Form.Group>
        <div className="container">
          <Form.Group as={Row} className="mb-4">
            <Col>
              <Form.Label>
                Approvazioni richieste
              </Form.Label>
            </Col>
            <Col className="text-center justify-content-between d-flex">
              <Button
                variant="outline-primary"
                onClick={(ev) => { decreaseSoglie() }}
              >
                <strong>-</strong>
              </Button>
              <Form.Label className="my-auto">
                <strong>
                  {calcolaTotaleSoglieAttive()}
                </strong>
              </Form.Label>
              <Button
                variant="outline-primary"
                onClick={(ev) => { increaseSoglie() }}
              >
                <strong>+</strong>
              </Button>
            </Col>
          </Form.Group>

          {orderSoglieByOrdine().map((soglia, index) => (
            soglia.sogActive === true &&
              <Form.Group key={index} as={Row} className="mb-4">
                <Col>
                  <Form.Label>
                    Al raggiungimento di
                  </Form.Label>
                </Col>
                <Col>
                  <InputGroup>
                    <Form.Control
                      value={parseInt(soglia.sogValore)}
                      type="number"
                      className="text-center"
                      step={stepSoglie}
                      onChange={(ev) => {
                        props.svuotaMessaggi()
                        editSoglia(ev.target.value, soglia.sogOrdine)
                      }}
                    />
                  </InputGroup>
                </Col>
              </Form.Group>
          ))}
        </div>
      </Col>
    </>
  );

}