import { useState, useEffect } from "react"
import { Row, Col, Dropdown, NavDropdown, Button, Table, Form, FormControl, Badge, CloseButton, InputGroup } from "react-bootstrap"
import AzioniTabella from "./AzioniTabella";
import { GoSearch } from "react-icons/go"
import { IoMdAdd } from "react-icons/io"
import { getBilancioColor } from '../../Utilities/getBorderColor'
import { getStatusBilancio } from "../../Utilities/getStatus"
import { getStatusOrderBilancio } from "../../Utilities/getStatusOrder";
import dayjs from "dayjs";
import { statiBilancio } from "../../Utilities/statiElementEnum";

export default function TabellaBilanci(props) {

    let [bilanci, setBilanci] = useState([])
    const [titolo, setTitolo] = useState("")

    const getBilanci = () => {
        let ret = bilanci.map(bil => {
            if (bil.bilStato === statiBilancio.InCorso) {
                let date1 = dayjs()
                let date2 = dayjs(bil.bilDataChiusura)
                if (date1.diff(date2) > 0)
                    return { ...bil, bilStato: statiBilancio.Concluso }
                else
                    return bil
            }
            return bil
        })

        if (props.statoFilter.length > 0)
            ret = ret.filter((bil) => bil.bilStato === props.statoFilter)

        if (titolo)
            ret = ret.filter((bil) => bil.bilTitolo.toLowerCase().includes(titolo.toLowerCase()))
        return ret
    }

    const addBilancio = () => {
        props.setMostraTabella(false)
        props.setGestisci("addNew")
    }

    let filterByStato = (stato) => {
        props.setStatoFilter(stato)
    }

    let resetStato = () => {
        props.setStatoFilter("")
    }

    useEffect(() => {
        let bilanci = []
        props.bilanci.forEach(bil => {
            let bilancio = {
                bilId: bil.bilId,
                bilEdizione: bil.bilEdizione,
                bilTitolo: bil.bilTitolo,
                bilStato: getStatusBilancio(bil),
                bilDataInizio: bil.bilDataInizio,
                bilDataChiusura: bil.bilDataChiusura
            }
            bilanci.push(bilancio)
        })

        setBilanci(bilanci)
    }, [props])

    return (
        <>
            <Row className="d-flex justify-content-between my-4">
                <Col xs={{span:12, order:2}} md={{span:3, order:1}}>
                    <Dropdown className="d-inline" autoClose="inside">
                        <Dropdown.Toggle id="dropdown-autoclose-outside">
                            <span className="px-5">Filtra</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <NavDropdown title="Stato" className="noChangeOnClick" drop="end" onSelect={filterByStato}>
                                {Object.values(statiBilancio)
                                    .map((stato, index) => (
                                        <NavDropdown.Item key={index} eventKey={stato}>{stato}</NavDropdown.Item>
                                    )
                                    )}
                            </NavDropdown>
                        </Dropdown.Menu>
                    </Dropdown>
                    {
                        props.statoFilter.length > 0 &&
                        <Form.Label className="mx-4 mt-2 mt-md-0">
                            Stato: <strong> {props.statoFilter}</strong>
                            <Badge bg="outline-danger">
                                <CloseButton onClick={resetStato} />
                            </Badge>
                        </Form.Label>
                    }
                </Col>

                <Col xs={{span:12, order:1}} md={{span:3, offset:2,  order:2}} className="text-md-end">
                    <Button onClick={addBilancio} className="mx-md-2 mb-2 mb-md-0">
                        Nuovo  <IoMdAdd style={{ fontSize: "1.3em" }} /></Button>
                </Col>
                <Col xs={{span:12, order:3}} md={{span:4, order:3}}>

                    <InputGroup className="mt-2 mt-md-0">
                        <InputGroup.Text id="basic-addon1">
                            <GoSearch />
                        </InputGroup.Text>
                        <FormControl placeholder="Cerca titolo..."
                            onChange={(ev) => { setTitolo(ev.target.value) }}
                        />
                    </InputGroup>
                </Col>
            </Row>

            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Edizione</th>
                        <th>Titolo</th>
                        <th className="text-center">Stato</th>
                        <th className="text-center">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        getBilanci().length > 0 ? (
                            getBilanci()
                                .sort((a, b) => { return getStatusOrderBilancio(b.bilEdizione) - getStatusOrderBilancio(a.bilEdizione) })
                                .map(bilancio => (
                                    <tr key={bilancio.bilId}>
                                        <td>{bilancio.bilEdizione}</td>
                                        <td>{bilancio.bilTitolo}</td>
                                        <td className="text-center">
                                            <Badge pill bg={getBilancioColor(bilancio)} className="pillText" style={{ minWidth: "90%" }}>
                                                {bilancio.bilStato}
                                            </Badge>
                                        </td>
                                        <td>
                                            <AzioniTabella
                                                bilId={bilancio.bilId}
                                                setBilId={props.setBilId}
                                                stato={bilancio.bilStato}
                                                setMostraTabella={props.setMostraTabella}
                                                setGestisci={props.setGestisci}
                                            />
                                        </td>
                                    </tr>
                                ))
                        ) : (
                            <tr>
                                <td colSpan={4}>
                                    Nessun bilancio trovato
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    )

}