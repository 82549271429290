
import React from "react";
import { Card, Col, Row, Container, Nav, Spinner } from "react-bootstrap";
import Slider from "react-slick";
import { RiEmotionSadLine } from "react-icons/ri"
import CartaBilancio from "./CartaBilancio";
import CartaSommario from "./CartaSommario";
import CartaSommarioSegnalazione from "./CartaSommarioSegnalazione";
import CartaCrea from "./CartaCrea";

import { FiChevronRight } from 'react-icons/fi'
import { useNavigate } from "react-router-dom";

import seg2 from '../../../immagini/homepage/segnalazioni_icona_2.png'
import seg3 from '../../../immagini/homepage/segnalazioni_icona_3.png'
import seg1 from '../../../immagini/homepage/segnalazioni_icona_1.png'


import dayjs from "dayjs";


export default function CartaElementiHomepage(props) {
    const navigate = useNavigate();

    const goToPath = (path) => {
        navigate(path)
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        nextArrow: <Arrow />,
        prevArrow: <Arrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
    };

    const settingsBilanci={
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <Arrow />,
        prevArrow: <Arrow />
    }


    return (
        <div className="mb-5 h-100">
            <Row style={{ maxWidth: "99vw" }} className="p-md-4 pb-md-2 mb-md-3">
                <Col>
                    <h1 className="title ms-2 mt-2 mt-md-0" id={props.identificativo}>{props.titolo}</h1>
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                    <Nav.Item>
                        <Nav.Link className="p-0 p-md-2" onClick={() => { goToPath(props.path) }}>{props.testoLink}
                            <FiChevronRight />
                        </Nav.Link>
                    </Nav.Item>
                </Col>
            </Row>
            {
                props.loading ?
                    <Container className="text-center mb-5">
                        <Spinner animation="border" role="status" />
                    </Container>
                    :
                    (props.mode !== "segnalazione" && props.listaElementi && props.listaElementi.length === 0) ?
                        <Container className="mb-5">
                            <Card>
                                <Card.Body >
                                    <RiEmotionSadLine size="2em" className="me-2" />
                                    <span>Non è ancora presente nessun elemento per questa categoria!</span>
                                </Card.Body>
                            </Card>
                        </Container>
                        :
                        <>
                            {(props.mode === "petizione" || props.mode === "referendum") &&
                                <div className="slider-container h-100">

                                    <Slider {...settings} className="h-100">
                                        {/* // <Carousel className="grabbable" breakPoints={breakPoints}> */}

                                        <CartaCrea mode={props.mode} partecipante={props.partecipante} />
                                        {props.listaElementi && props.listaElementi
                                            .map((elemento, index) => (
                                                <CartaSommario
                                                    key={index}
                                                    elemento={elemento}
                                                    comune={props.comune}
                                                    partecipante={props.partecipante}
                                                    mode={props.mode}
                                                />
                                            ))
                                        }
                                        {/* // </Carousel> */}
                                    </Slider>
                                </div>
                            }

                             {props.mode === "bilancio" &&
                             <>
                              <div className="slider-container h-100">

                              <Slider {...settingsBilanci} className="h-100">
                                    {props.listaElementi && props.listaElementi
                                        .map((bilancio, index) => (
                                            <CartaBilancio
                                            key={index}
                                            bilancio={bilancio}
                                            partecipante={props.partecipante}
                                            />
                                        ))}
                                </Slider>
                                </div>
                                        </>
                            }

                             {    props.mode === "segnalazione" &&
                                 <>
                                 <div className="slider-container h-100">
   
                                 <Slider {...settings} className="h-100">
                                    <CartaCrea mode={"segnalazione"} partecipante={props.partecipante} style={{ width: "auto" }} />
                                    <CartaSommarioSegnalazione
                                        titolo={"Aiuta il tuo Comune a migliorare!"}
                                        descrizione={"Segnala i disservizi nel tuo Comune per richiedere una rapida soluzione dall'amministrazione."}
                                        data={dayjs().subtract(3, 'day').format("DD/MM/YYYY")}
                                        img={seg1} />
                                    <CartaSommarioSegnalazione
                                        titolo={"La tua voce conta!"}
                                        descrizione={"Le segnalazioni che invii aiutano a migliorare la qualità dei servizi offerti dall'amministrazione! Fai sentire la tua voce!"}
                                        data={dayjs().subtract(2, 'day').format("DD/MM/YYYY")}
                                        img={seg2} />
                                    <CartaSommarioSegnalazione
                                        titolo={"Per la città come la desideri!"}
                                        descrizione={"Questo è il tuo canale di comunicazione per suggerire degli interventi all'amministrazione. Cambia la faccia della tua città."}
                                        data={dayjs().format("DD/MM/YYYY")}
                                        img={seg3} />
                                </Slider>
                                </div>
                                </>} 


                                </>

            }
        </div>



    )
}

function Arrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  }
  
