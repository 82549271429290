import { Container, Row, Col, Dropdown, NavDropdown, Form, FormControl, Badge } from "react-bootstrap"
import { CloseButton, Button, Table, InputGroup } from "react-bootstrap"
import { GoSearch } from "react-icons/go"
import AzioniProposte from "./AzioniProposte";
import { getPropostaColor } from '../../../Utilities/getBorderColor'
import { getStatusOrderProposta } from '../../../Utilities/getStatusOrder'
import { FiChevronLeft } from 'react-icons/fi'
import { statiProposta } from "../../../Utilities/statiElementEnum";

const dayjs = require('dayjs');

export default function ProposteElenco(props) {

    let aree = (props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcAreeUrbane && props.comune.impostazioniComune.imcAreeUrbane.aree_urbane) || ["Caricamento..."]
    let proposte = props.bilanci.filter(el => el.bilId === props.bilId)[0].proposte

    const getProposte = () => {
        let ret = proposte

        if (props.statoPropostaFilter.length > 0)
            ret = ret.filter((pro) => pro.proStato === props.statoPropostaFilter)

        if (props.areaFilter.length > 0)
            ret = ret.filter((pro) => pro.proArea === props.areaFilter)

        return ret
    }

    const redirectToList = () => {
        props.setMostraTabella(true)
        props.setStatoPropostaFilter("")
        props.setAreaFilter("")
    }

    let filterByStatoProposta = (statoProposta) => {
        props.setStatoPropostaFilter(statoProposta)
    }

    let resetStatoProposta = () => {
        props.setStatoPropostaFilter("")
    }

    let filterByArea = (area) => {
        props.setAreaFilter(area)
    }

    let resetArea = () => {
        props.setAreaFilter("")
    }

    return (
        <>
            <Container className="p-0 px-md-4 py-md-0">
                <h2 className="title2">
                    Proposte per: {props.bilanci.filter(el => el.bilId === props.bilId)[0].bilTitolo}
                </h2>

                <Row className="d-flex justify-content-between my-4">
                    <Col>
                        <Dropdown className="d-inline" autoClose="inside">
                            <Dropdown.Toggle id="dropdown-autoclose-outside">
                                <span className="px-5">Filtra</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <NavDropdown title="Stato" className="noChangeOnClick" drop="end" onSelect={filterByStatoProposta}>
                                {Object.values(statiProposta)
                                    .map((stato, index) => (
                                        <NavDropdown.Item key={index} eventKey={stato}>{stato}</NavDropdown.Item>
                                    )
                                    )}
                                </NavDropdown>
                                <NavDropdown title="Area" className="noChangeOnClick" drop="end" onSelect={filterByArea}>
                                    {
                                        aree.map((area, index) => (
                                            <NavDropdown.Item key={index} eventKey={area}>{area}</NavDropdown.Item>
                                        ))
                                    }
                                </NavDropdown>
                            </Dropdown.Menu>
                        </Dropdown>
                        {
                            props.statoPropostaFilter.length > 0 &&
                            <Form.Label className="mx-4 mt-2 mt-md-0">
                                Stato: <strong> {props.statoPropostaFilter}</strong>
                                <Badge bg="outline-danger">
                                    <CloseButton onClick={resetStatoProposta} />
                                </Badge>
                            </Form.Label>
                        }
                        {
                            props.areaFilter.length > 0 &&
                            <Form.Label className="mx-4 mt-2 mt-md-0">
                                Area: <strong> {props.areaFilter}</strong>
                                <Badge bg="outline-danger">
                                    <CloseButton onClick={resetArea} />
                                </Badge>
                            </Form.Label>
                        }
                    </Col>

                    <Col xs={12} md={5} className="d-flex justify-content-end">
                        <InputGroup className="mt-2 mt-md-0">
                            <InputGroup.Text id="basic-addon1">
                                <GoSearch />
                            </InputGroup.Text>
                            <FormControl placeholder="Cerca titolo..." />
                        </InputGroup>
                    </Col>
                </Row>

                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Titolo</th>
                            <th>Autore</th>
                            <th>Categorie</th>
                            <th className="text-center">Area</th>
                            <th className="text-center">Stato</th>
                            <th className="text-center">Voti</th>
                            <th className="text-center nowrap">Pubblicata il</th>
                            <th className="text-center">Azioni</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            getProposte().length > 0 ? (
                                getProposte()
                                    .sort((a, b) => { return getStatusOrderProposta(a.proStato) - getStatusOrderProposta(b.proStato) })
                                    .map(proposta => (
                                        <tr key={proposta.proId}>
                                            <td>{proposta.proTitolo}</td>
                                            <td>{proposta.partecipante.datiPartecipante.dparNome} {proposta.partecipante.datiPartecipante.dparCognome}</td>
                                            <td>{proposta.proCategorie.categorie.join(", ")}</td>
                                            <td className="text-center">{proposta.proArea}</td>
                                            <td className="text-center">
                                                <Badge pill bg={getPropostaColor(proposta)} className="pillText" style={{ minWidth: "90%" }}>
                                                    {proposta.proStato}
                                                </Badge>
                                            </td>
                                            <td className="text-center">{proposta.proVotiOffline}</td>
                                            <td className="text-center">
                                                {proposta.proDataPubblicazione ? dayjs(proposta.proDataPubblicazione).format('DD/MM/YYYY') : "---"}
                                            </td>
                                            <td>
                                                <AzioniProposte
                                                    stato={proposta.proStato}
                                                    setGestisci={props.setGestisci}
                                                    setGestisciProposta={props.setGestisciProposta}
                                                    proposta={proposta}
                                                    setProposta={props.setProposta}
                                                />
                                            </td>
                                        </tr>
                                    ))
                            ) : (
                                <tr>
                                    <td colSpan={8}>
                                        Nessuna proposta trovata
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

                <Row>
                    <Container className="d-flex justify-content-between p-0 m-0 mt-4 mb-5">
                        <Button variant="outline-dark" onClick={redirectToList}>
                            <FiChevronLeft />
                            Indietro
                        </Button>

                    </Container>
                </Row>
            </Container>
        </>
    )

}