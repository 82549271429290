import axios from 'axios'

const PARTECIPANTE_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'partecipante'

const headers = { 'Content-Type': 'application/json' }

class PartecipanteService {
    getPartecipante(id) {
        return axios.get(PARTECIPANTE_REST_API_URL + `/${id}`)
    }

    login(dparEmail, dparPassword) {
        return axios.get(PARTECIPANTE_REST_API_URL + `/login`,
            {
                params: {
                    dparEmail: dparEmail,
                    dparPassword: dparPassword
                }
            })
    }

    findByComune(comId) {
        return axios.get(PARTECIPANTE_REST_API_URL + `/comune/${comId}`)
    }

    confermaToken(token) {
        return axios.post(PARTECIPANTE_REST_API_URL + `/confermaToken/${token}`)
    }

    nuovoAdmin(comId, partecipante){
        return axios.post(PARTECIPANTE_REST_API_URL + `/nuovoAdmin/${comId}`,
            {
                    "dparCf": partecipante.dparCf,
                    "dparCognome": partecipante.dparCognome,
                    "dparDataNascita": partecipante.dparDataNascita,
                    "dparEmail": partecipante.dparEmail,
                    "dparNome": partecipante.dparNome
            })
    }

    
    findByCf(dparCf) {

        return axios.get(PARTECIPANTE_REST_API_URL + `/findByCf`,
            {
                params: {
                    dparCf: dparCf
                }
            })
    }

    importPartecipanti(comId, partecipantiCSV) {
        return axios.post(PARTECIPANTE_REST_API_URL + `/csv/upload/${comId}`, partecipantiCSV, headers)
    }

    bloccaPartecipante(parId, flag){
        return axios.post(PARTECIPANTE_REST_API_URL+ `/blocca/${parId}`, null, 
            {
                params: {
                    "flag": flag
                }
            })
    }

}

const partecipanteServiceInstance = new PartecipanteService()
export default partecipanteServiceInstance;