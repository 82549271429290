import { Button, Container, OverlayTrigger, Tooltip, Col, Row, Modal, Form } from "react-bootstrap"
import { BiBlock } from "react-icons/bi"
import { HiOutlineKey } from "react-icons/hi"
import { BsCheckSquare } from "react-icons/bs"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import PartecipanteService from "../../../services/PartecipanteService"
import { IoIosCog } from "react-icons/io";

export default function AzioniPerVerificati(props) {
    const [show, setShow] = useState(false)
    const [mode, setMode] = useState("")

    return (
        <>
            <Container className="d-flex justify-content-around p-0 m-0">
                {props.partecipante.parStato === 'bloccato' ?
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip>Sblocca</Tooltip>
                        }>
                        <Button
                            variant="noBackground p-0"
                            onClick={() => { setShow(true); setMode("Sblocca") }}
                        >
                            <IoIosCog size="1.5em" />
                        </Button>
                    </OverlayTrigger>
                    :
                    <>

                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip>Blocca</Tooltip>
                            }>
                            <Button
                                variant="noBackground p-0"
                                onClick={() => { setShow(true); setMode("Blocca") }}
                            >
                                <BiBlock size="1.5em" />
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip>Modifica permessi</Tooltip>
                            }>
                            <Button
                                variant="noBackground p-0"
                                onClick={() => { setShow(true); setMode("Modifica permessi") }}
                            >
                                <HiOutlineKey size="1.5em" />
                            </Button>
                        </OverlayTrigger>
                    </>}
            </Container >
            <ModaleVerificato
                partecipante={props.partecipante}
                show={show}
                setShow={setShow}
                mode={mode}
                setRicaricaPartecipanti={props.setRicaricaPartecipanti}
            />
        </>

    )
}


function ModaleVerificato(props) {
    const [checks, setChecks] = useState(
        {
            votante_petizioni: false,
            votante_referendum: false,
            votante_proposte: false,
            pubblicatore_petizioni: false,
            pubblicatore_referendum: false,
            pubblicatore_proposte: false
        })
    const [ruolo, setRuolo] = useState(false)


    const handleClick = () => {
        PartecipanteService.bloccaPartecipante(props.partecipante.parId, props.mode==='Sblocca')
            .then(res => {
                handleClose()
                props.setRicaricaPartecipanti(true);
            })
            .catch(err => {
                console.log(err)
            })
    }


    useEffect(() => {
        if (props.partecipante && props.partecipante.ruolo) {
            setChecks(
                {
                    votante_petizioni: props.partecipante.ruolo.ruoFlVotantePetizioni,
                    votante_referendum: props.partecipante.ruolo.ruoFlVotanteReferendum,
                    votante_proposte: props.partecipante.ruolo.ruoFlVotanteProposte,
                    pubblicatore_petizioni: props.partecipante.ruolo.ruoFlPubblicatorePetizioni,
                    pubblicatore_referendum: props.partecipante.ruolo.ruoFlPubblicatoreReferendum,
                    pubblicatore_proposte: props.partecipante.ruolo.ruoFlPubblicatoreProposte
                }
            )
            setRuolo(props.partecipante.ruolo.ruoFlAmministratore)
        }
    }, [props.partecipante])

    const handleClose = () => props.setShow(false)
    return (
        <Modal show={props.show} onHide={handleClose}
            centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{props.mode} partecipante</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col>
                        <p><strong>Nome:</strong> {props.partecipante.datiPartecipante.dparNome}</p>
                    </Col>
                    <Col>
                        <p><strong>Cognome:</strong> {props.partecipante.datiPartecipante.dparCognome}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p><strong>Codice fiscale:</strong> {props.partecipante.datiPartecipante.dparCf}</p>
                    </Col>
                    <Col>
                        <p><strong>Data di nascita:</strong> {dayjs(props.partecipante.datiPartecipante.dparDataNascita).format("DD/MM/YYYY")}</p>
                    </Col>
                </Row>

                {props.mode === "Modifica permessi" ?
                    <>
                        <Row>
                            <Row>
                                <Col>
                                    <p><strong>Permessi:</strong></p>
                                </Col>
                                <Col >
                                    <Button className="p-0 px-1 ms-3 mt-1"
                                        onClick={() => {
                                            setChecks({
                                                votante_petizioni: true,
                                                votante_referendum: true,
                                                votante_proposte: true,
                                                pubblicatore_petizioni: true,
                                                pubblicatore_referendum: true,
                                                pubblicatore_proposte: true
                                            })
                                        }}>
                                        <BsCheckSquare className="mb-1" /> Seleziona tutti</Button>
                                </Col>
                            </Row>
                            <Col>
                                <Form.Check
                                    label="Votante petizioni"
                                    type="checkbox"
                                    checked={checks.votante_petizioni}
                                    onChange={() => { setChecks(old => ({ ...old, votante_petizioni: !old.votante_petizioni })) }}

                                />
                                <Form.Check
                                    label="Votante referendum"
                                    type="checkbox"
                                    checked={checks.votante_referendum}
                                    onChange={() => { setChecks(old => ({ ...old, votante_referendum: !old.votante_referendum })) }}
                                />

                                <Form.Check
                                    label="Votante proposte"
                                    type="checkbox"
                                    checked={checks.votante_proposte}
                                    onChange={() => { setChecks(old => ({ ...old, votante_proposte: !old.votante_proposte })) }}
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    label="Pubblicatore petizioni"
                                    type="checkbox"
                                    checked={checks.pubblicatore_proposte}
                                    onChange={() => { setChecks(old => ({ ...old, pubblicatore_proposte: !old.pubblicatore_proposte })) }}
                                />
                                <Form.Check
                                    label="Pubblicatore referendum"
                                    type="checkbox"
                                    checked={checks.pubblicatore_proposte}
                                    onChange={() => { setChecks(old => ({ ...old, pubblicatore_proposte: !old.pubblicatore_proposte })) }}
                                />

                                <Form.Check
                                    label="Pubblicatore proposte"
                                    type="checkbox"
                                    checked={checks.pubblicatore_proposte}
                                    onChange={() => { setChecks(old => ({ ...old, pubblicatore_proposte: !old.pubblicatore_proposte })) }}
                                />
                            </Col>
                        </Row>

                        <p className="mt-4 mb-2"><strong>Ruolo:</strong></p>
                        <Row>
                            <Col>
                                <Form.Check
                                    className="w-auto"
                                    label="Amministratore"
                                    name="group"
                                    type="radio"
                                    checked={ruolo}
                                    onChange={() => { setRuolo(old => !old) }}
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    className="w-auto"
                                    label="Visualizzatore"
                                    name="group"
                                    type="radio"
                                    checked={!ruolo}
                                    onChange={() => { setRuolo(old => !old) }}
                                />
                            </Col>
                        </Row>
                    </>
                    :
                    (props.mode === 'Blocca' ?
                        <>
                            <Container className="text-center mt-2">
                                <p>Sei sicuro di voler bloccare questo partecipante?</p>
                                <Button variant="outline-danger"
                                    onClick={handleClick} >Si, blocca</Button>
                            </Container>
                        </>
                        :
                        <>
                            <Container className="text-center mt-2">
                                <p>Sei sicuro di voler sbloccare questo partecipante?</p>
                                <Button variant="outline-danger"
                                    onClick={handleClick} >Si, sblocca</Button>
                            </Container>
                        </>
                    )
                }
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary"
                    onClick={handleClose}>Chiudi</Button>
            </Modal.Footer>
        </Modal >
    )
}