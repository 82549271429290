import { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import ModalConferma from "../../Utilities/ModalConferma";
import ImpostazioniComuneService from "../../../services/ImpostazioniComuneService";

export default function ImpostazioniModuli(props) {

    let [imcModuloPetizioniAttivo, setImcModuloPetizioniAttivo] = useState(false)
    let [imcModuloReferendumAttivo, setImcModuloReferendumAttivo] = useState(false)
    let [imcModuloSegnalazioniAttivo, setImcModuloSegnalazioniAttivo] = useState(false)
    let [imcModuloBilanciAttivo, setImcModuloBilanciAttivo] = useState(false)
    const [mostraModalOk, setMostraModalOk] = useState(false)
    const [mostraModalFailed, setMostraModalFailed] = useState(false)
    const [mostraModalCancelOk, setMostraModalCancelOk] = useState(false)

    useEffect(() => {
        setImcModuloPetizioniAttivo((props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloPetizioniAttivo) || 0)
        setImcModuloReferendumAttivo((props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloReferendumAttivo) || 0)
        setImcModuloSegnalazioniAttivo((props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloSegnalazioniAttivo) || 0)
        setImcModuloBilanciAttivo((props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloBilanciAttivo) || 0)
    }, [props.comune])

    const preparaSalva = () => {
        setMostraModalCancelOk(true)
    }

    const salvaImpostazioni = async () => {
        let successSaved = false

        let impostazioni = {
            ...props.comune.impostazioniComune,
            imcModuloPetizioniAttivo: imcModuloPetizioniAttivo,
            imcModuloReferendumAttivo: imcModuloReferendumAttivo,
            imcModuloSegnalazioniAttivo: imcModuloSegnalazioniAttivo,
            imcModuloBilanciAttivo: imcModuloBilanciAttivo,
            comune: props.comune
        }

        setMostraModalCancelOk(false)

        try {
            let response = await ImpostazioniComuneService.aggiornaImpostazioni(impostazioni)
            if (response.status === 200) {
                successSaved = true
                props.setRicaricaComune(true)
            } else if (response.status === 500)
                setMostraModalFailed(false)
        } catch (err) {
            setMostraModalFailed(false)
        }

        if (successSaved)
            setMostraModalOk(true)
    }

    const closeOkModal = () => {
        setMostraModalOk(false)
    }

    const closeOkFailed = () => {
        setMostraModalFailed(false)
    }

    return (
        <>
            <Form>
                <Row className="d-flex">
                    <Col>
                        <Form.Label column>
                            <strong>Attivazione moduli</strong>
                        </Form.Label>
                    </Col>
                </Row>
                <Row className="mb-3 mb-md-0">
                    <Col>
                        <Form.Group as={Row}>
                        <Form.Label column sm="4">
                        Petizioni:
                            </Form.Label>
                            <Col xs={6}>
                                <Form.Label className="small">No</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="attiva-petizioni"
                                    className="inline mx-2"
                                    checked={imcModuloPetizioniAttivo}
                                    onChange={(ev) => {
                                        setImcModuloPetizioniAttivo(ev.target.checked)
                                    }}
                                />
                                <Form.Label className="small">Sì</Form.Label>
                                </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3 mb-md-0">
                    <Col>
                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                Referendum:
                            </Form.Label>
                            <Col xs={6}>
                                <Form.Label className="small">No</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="attiva-petizioni"
                                    className="inline mx-2"
                                    checked={imcModuloReferendumAttivo}
                                    onChange={(ev) => {
                                        setImcModuloReferendumAttivo(ev.target.checked)
                                    }}
                                />
                                <Form.Label className="small">Sì</Form.Label>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3 mb-md-0">
                    <Col>
                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                Segnalazioni:
                            </Form.Label>
                            <Col xs={6}>
                                <Form.Label className="small">No</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="attiva-petizioni"
                                    className="inline mx-2"
                                    checked={imcModuloSegnalazioniAttivo}
                                    onChange={(ev) => {
                                        setImcModuloSegnalazioniAttivo(ev.target.checked)
                                    }}
                                />
                                <Form.Label className="small">Sì</Form.Label>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3 mb-md-0">
                    <Col>
                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                Bilanci partecipativi:
                            </Form.Label>
                            <Col xs={6}>
                                <Form.Label className="small">No</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="attiva-petizioni"
                                    className="inline mx-2"
                                    checked={imcModuloBilanciAttivo}
                                    onChange={(ev) => {
                                        setImcModuloBilanciAttivo(ev.target.checked)
                                    }}
                                />
                                <Form.Label className="small">Sì</Form.Label>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <Container className="d-flex justify-content-end w-90 p-0 pt-5">
                <Button onClick={preparaSalva}>Aggiorna</Button>
            </Container>

            <ModalConferma
                mostra={mostraModalOk}
                setMostra={setMostraModalOk}
                mode="success"
                titolo="Salvataggio impostazioni"
                messaggio="Impostazioni salvate con successo"
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={closeOkModal}
            />

            <ModalConferma
                mostra={mostraModalFailed}
                setMostra={setMostraModalFailed}
                mode="error"
                titolo="Salvataggio impostazioni"
                messaggio="Errore nel salvataggio delle impostazioni"
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={closeOkFailed}
            />

            <ModalConferma
                mostra={mostraModalCancelOk}
                setMostra={setMostraModalCancelOk}
                mode="primary"
                titolo="Salvataggio impostazioni"
                messaggio="Confermi il salvataggio delle impostazioni?"
                bodyAlign="text-center"
                btnAnnulla="No, annulla"
                btnConferma="Sì, salva"
                azioneConferma={salvaImpostazioni}
            />
        </>
    )
}