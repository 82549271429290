import { statiPetizione, statiReferendum, statiProposta, statiSegnalazione } from "./statiElementEnum"

function getStatusOrderPetizione(stato) {
    let stati = {
        [statiPetizione.InValidazione]: 1,
        [statiPetizione.InCorso]: 2,
        [statiPetizione.InAttesa]: 3,
        [statiPetizione.Conclusa]: 4,
        [statiPetizione.FirmeNonRaggiunte]: 5,
        [statiPetizione.Rifiutata]: 6
    }

    return stati[stato]
}

function getStatusOrderReferendum(stato) {
    let stati = {
        [statiReferendum.InValidazione]: 1,
        [statiReferendum.InCorso]: 2,
        [statiReferendum.InAttesa]: 3,
        [statiReferendum.FirmeRaggiunte]: 4,
        [statiReferendum.Concluso]: 5,
        [statiReferendum.FirmeNonRaggiunte]: 6,
        [statiReferendum.Rifiutato]: 7
    }

    return stati[stato]
}

function getStatusOrderBilancio(edizione) {
    let e = edizione.replace("Edizione", " ")
    if (e.includes(" - "))
        return parseInt(e.split("/")[0])
    else
        return parseInt(e)
}

function getStatusOrderProposta(stato) {
    let stati = {
        [statiProposta.InValidazione]: 1,
        [statiProposta.Vincitrice]: 2,
        [statiProposta.Pubblicata]: 3,
        [statiProposta.Rifiutata]: 4
    }

    return stati[stato]
}

function getStatusOrderSegnalazione(stato) {
    let stati = {
        [statiSegnalazione.InAttesa]: 1,
        [statiSegnalazione.Chiusa]: 2,
        [statiSegnalazione.Rifiutata]: 3
    }

    return stati[stato]
}

export { getStatusOrderPetizione, getStatusOrderReferendum, getStatusOrderBilancio, getStatusOrderProposta, getStatusOrderSegnalazione }