import { Button, Card, Col, Row, Badge } from 'react-bootstrap'
import { FiChevronRight as Scopri } from 'react-icons/fi'
import { getBilancioColor } from '../../Utilities/getBorderColor'
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ModalPermessi from "../../Utilities/ModalPermessi";
import dayjs from "dayjs";
import { statiBilancio } from '../../Utilities/statiElementEnum';

export default function CartaBilancio(props) {
    const navigate = useNavigate();

    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);
    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();
    
    const redirect = () => {
        navigate("/bilancio", { state: { bilId: props.bilancio.bilId, activeTab: "proposte" } })
    }

    const canView = () => {
        // 1. no permesso visua elemento
        //2. non loggato
        //3. con permessi
        if ((props.partecipante && props.partecipante.ruolo
            && props.partecipante.ruolo.ruoFlVisualizzatore) ||
            !(props.partecipante && props.partecipante.parId)) {
            //caso 3
            navigate('/bilancio', { state: { bilId: props.bilancio.bilId, activeTab: "info" } })

        } else {
            // caso 2
            setTitolo("Attenzione: operazione illecita")
            setMessaggio(`Attualmente non possiedi i permessi per visualizzare il bilancio partecipativo. L'operazione è bloccata!`)
            setMode("danger")
            setMostraModalPermessi(true)
        }
    }

    return (
        <Card className="cartaSommarioBorder"
            border={getBilancioColor(props.bilancio)}
            style={{ minWidth: "70vw" }}>

            <Card.Body className='d-flex flex-column justify-content-between'>
                <Row className='m-0 p-0 w-10' style={{"height":"100%"}}>
                    <Col lg={7} xs={12}>
                        <span className='text-muted smallcaps w-auto my-auto' style={{ fontSize: "0.9em" }}>{props.bilancio.bilEdizione}</span>
                        <h4 className="borderTitle" onClick={canView}><strong>{props.bilancio.bilTitolo}</strong></h4>
                        <span>{props.bilancio.bilDescrizione.length > 500 ? `${props.bilancio.bilDescrizione.slice(0, 500)}...` : props.bilancio.bilDescrizione}</span>
                    </Col>
                    <Col lg={5} xs={12}>
                        <CartaInfoBilancio bilancio={props.bilancio} />
                    </Col>
                </Row>
                <Row className='m-0 mt-3 p-0 w-10 justify-content-end ' >
                    <Col>
                        <Badge
                            pill
                            bg={getBilancioColor(props.bilancio)}
                            className="mt-auto w-auto me-2 py-2"
                            style={{ fontWeight: '100', fontSize: '0.80em', whiteSpace: "normal" }}>
                            {props.bilancio.bilStato}
                        </Badge>
                    </Col>
                    <Col lg={5} xs={8} className="d-flex justify-content-between">
                        <Button variant="outline-dark" className='w-s me-3 '
                            onClick={() => {
                                redirect()
                            }}>
                            Scopri le proposte{' '}
                            <Scopri />
                        </Button>

                        <Button variant="outline-dark" className='w-s'
                            onClick={canView}>
                            Scopri di più{' '}
                            <Scopri />
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
            <ModalPermessi
                mostra={mostraModalPermessi}
                setMostra={setMostraModalPermessi}
                titolo={titolo}
                messaggio={messaggio}
                mode={mode}
            />
        </Card >
    )
}

function CartaInfoBilancio(props) {
    
    let [bilancio, setBilancio] = useState()

    useEffect(() => {
        const today = dayjs()
        let bilStato = props.bilancio.bilStato
        let faseCurrent

        if (props.bilancio.bilStato === statiBilancio.InCorso){
            let dataChiusura = dayjs(props.bilancio.bilDataChiusura)
            if (today.diff(dataChiusura) > 0)
                bilStato = statiBilancio.Concluso
        }
        if (bilStato === statiBilancio.InCorso){
            faseCurrent = props.bilancio.fasi.filter(fase => (
                (dayjs(fase.fasDataInizio).isBefore(today) || dayjs(fase.fasDataInizio).isSame(today)) &&
                (dayjs(fase.fasDataFine).isAfter(today) || dayjs(fase.fasDataFine).isSame(today))
            ))[0]
        }
        if (bilStato === statiBilancio.Concluso){
            let faseLast = props.bilancio.fasi.filter(fase =>fase.fasOrdine === props.bilancio.fasi.length)[0]
            faseCurrent = {
                fasOrdine: faseLast.fasOrdine,
                fasTitolo: faseLast.fasTitolo,
                fasDescrizione: faseLast.fasDescrizione
            }
        }
        setBilancio({
            stato: bilStato,
            fasiCount: props.bilancio.fasi.length,
            faseCurrent: faseCurrent
        })
    }, [props.bilancio])

    return (
        <Card className='mt-3 mt-lg-0 card-height-lg'>
            <Card.Body>
                {bilancio && bilancio.faseCurrent && bilancio.fasiCount > 0 ? (
                    <>
                        <span className='text-muted smallcaps' style={{ fontSize: "0.9em" }}>
                            Fase { bilancio.faseCurrent.fasOrdine} di {bilancio.fasiCount}
                        </span>
                        <h5 className='smallcaps'><strong>{ bilancio.faseCurrent.fasTitolo}</strong></h5>
                        <p style={{ fontSize: "0.9em" }}>{ bilancio.faseCurrent.fasDescrizione.length > 500 ? `${ bilancio.faseCurrent.fasDescrizione.slice(0,200)}...` : bilancio.faseCurrent && bilancio.faseCurrent.fasDescrizione }</p>
                    </>
                ) : (
                    <span>Nessuna fase presente</span>
                )}
            </Card.Body>
        </Card>
    )
}